import { AgGridReact } from "ag-grid-react";
import { AxiosResponse } from "axios";
import { useCallback, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useFetch from "../../../hooks/useFetchMSAL";
import { LotSizeGroupFetchAPI } from "../../../models/APIResponses.model";
import LotSizeGroup from "../../../models/PlanningParameters/LotSizeGroup.model";
import { AG_GRID_DEFAULT_COL_DEF, AG_GRID_MODULES, AG_GRID_OPTIONS, LOT_SIZE_GROUPS_TAB_ID, TOAST_CONTAINER_ID } from "../../../shared/constants";
import { getToastOptions, newGridRowStyleHandler, removeInsertedRowsById } from "../../../shared/functions";
import { planningParametersTabActions } from "../../../store/slices/planning-parameters-tab-slice";
import { RootState } from "../../../store";
import EditableGridActions from "../../UI/EditableGridActions/EditableGridActions";

const LotSizeGroupsGrid = (props: {
    gridHeight: string;
}) => {
    // States
    const [gridRowData, setGridRowData] = useState<LotSizeGroup[]>([]);
    const [gridRowDataCopy, setGridRowDataCopy] = useState<LotSizeGroup[]>([]);
    const [siteCodes, setSiteCodes] = useState<string[]>([]);
    const [lotSizePolicyNames, setLotSizePolicyNames] = useState<string[]>([]);
    const [siteCodeMappings, setSiteCodeMappings] = useState<{ [key: string]: string }>({});
    const [lotSizePolicyMappings, setLotSizePolicyMappings] = useState<{ [key: string]: number }>({});
    const [changeTrackState, setChangeTrackState] = useState<boolean>(false);
    const [insertedRows, setInsertedRows] = useState<any>({});
    const [selectedRowIds, setSelectedRowIds] = useState<any[]>([]);
    const [newRowCount, setNewRowCount] = useState<number>(1);
    const [enableDelButton, setEnableDelButton] = useState(false);
    const [cellEditTracks, setCellEditTracks] = useState<{
        [key: number]: {
            [key: string]: any;
        }
    }>({});

    // Variables
    const [, fetchData] = useFetch([]);
    const agGridRef = useRef<AgGridReact>(null);
    const gridColDef: any[] = [
        {
            headerName: "ID",
            field: "id",
            cellDataType: "number",
            hide: true
        },
        {
            headerName: "Group Policy Name",
            field: "group_policy_name",
            cellDataType: "text",
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: lotSizePolicyNames
            },
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
        },
        {
            headerName: "Location Name",
            field: "location_name",
            cellDataType: "text",
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: siteCodes
            },
        }
    ];

    // Redux variables
    const dispatchFn = useDispatch();
    const selectedSiteSlicers = useSelector((state: RootState) => state.planningParametersTab.selectedGridSlicers.location_name);
    const activeLowerSubTabId: string = useSelector((state: RootState) => state.planningParametersTab.activeSubTabId.lower);
    const tabApiDataFetched: boolean = useSelector((state: RootState) => state.planningParametersTab.tabApiDataFetched[LOT_SIZE_GROUPS_TAB_ID]);
    const isSiteMasterUpdated: boolean = useSelector((state: RootState) => state.masterTables.isSiteMasterUpdated);
    const isLotSizeOptimizationPolicyUpdated: boolean = useSelector((state: RootState) => state.planningParametersTab.isLotSizeOptimizationPolicyUpdated);

    /**
   * Fetches Lot Size Group data from API
   * @param {any} body Request body
   */
    const fetchLotSizeGroups = useCallback(async (body: any): Promise<void> => {
        setChangeTrackState(false);
        const fetchingDataToastId = toast.loading("Fetching Lot Size Group data...", {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("loading"),
        });
        try {
            const fetchAPIResponse: AxiosResponse<LotSizeGroupFetchAPI> = await fetchData(`/lot-size-groups`, {
                method: 'POST',
                data: body,
            });

            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID,
            });

            if (fetchAPIResponse.data.data) {
                setGridRowData(fetchAPIResponse.data.data);
                setGridRowDataCopy(
                    JSON.parse(JSON.stringify(fetchAPIResponse.data.data))
                );

                // Set dropdown values
                setSiteCodes((fetchAPIResponse.data as any).cell_dropdowns.location_name);
                setLotSizePolicyNames((fetchAPIResponse.data as any).cell_dropdowns.lot_size_policy_name);

                // Set dropdown mappings
                setSiteCodeMappings((fetchAPIResponse.data as any).mappings.location_mappings);
                setLotSizePolicyMappings((fetchAPIResponse.data.mappings as any).lot_size_policy_mappings);
                dispatchFn(planningParametersTabActions.setTabApiDataFetched({
                    [LOT_SIZE_GROUPS_TAB_ID]: true
                }));

            } else {
                toast.error("Error in fetching Lot Size Group data", {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions("error"),
                });
            }
        } catch (error: Error | any) {
            console.error(`Request Error: ${error}`);
            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID,
            });
            toast.error("Error in fetching Lot Size Group data", {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions("error"),
            });
        }
    }, [dispatchFn, fetchData]);

    /**
   * Event handler for when cell editing is stopped
   * @param {any} params Cell editing stop event parameters
   */
    const onCellEditingStoppedHandler = (params: any) => {
        try {
            const id: string = params.data.id;
            const colName: string = params.column.colId;

            if (id !== undefined && id.toString().startsWith("new_")) {
                setInsertedRows((prev: any) => {
                    let newRow = prev[id] ? prev[id] : {};

                    switch (colName) {
                        case "location_name":
                            newRow["site_code"] = siteCodeMappings[params.newValue];
                            break;
                        case "group_policy_name":
                            newRow["group_id"] = lotSizePolicyMappings[params.newValue];
                            break;
                        default:
                            newRow[colName] = params.newValue;
                    }

                    return {
                        ...prev,
                        [id]: newRow
                    };
                });
            } else {
                if (params.oldValue !== params.newValue && params.newValue !== undefined) {
                    let currentRowNode = agGridRef.current!.api!.getRowNode(params.node.id);

                    setCellEditTracks((prev) => {
                        let newTrackEdits: any = prev;
                        let cellEditTrack = newTrackEdits[id] ? newTrackEdits[id] : {};

                        switch (colName) {
                            case "location_name":
                                cellEditTrack["site_code"] = siteCodeMappings[params.newValue];
                                break;
                            case "group_policy_name":
                                cellEditTrack["group_id"] = lotSizePolicyMappings[params.newValue];
                                break;
                            default:
                                cellEditTrack[colName] = params.newValue;
                        }

                        return {
                            ...prev,
                            [id]: cellEditTrack
                        };
                    });
                    dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'lotSizeGroups', value: true }));

                    setChangeTrackState(true);
                    agGridRef.current!.api!.applyTransaction({
                        update: [currentRowNode?.data],
                    });
                } else if (params.newValue === params.oldValue) {
                    dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'lotSizeGroups', value: false }));

                }
            }

        } catch (error: any) {
            console.error(`Error: ${error}`);
        }
    };

    /**
   * Sets selected rows on selection checkbox change
   */
    const onSelectionChangedHandler = () => {
        const selectedRows = agGridRef.current!.api!.getSelectedRows();
        const selectedRowIds: any[] = selectedRows.map((el) => el.id);
        setSelectedRowIds(selectedRowIds);
    };

    /**
   * Deletes selected rows
   * @param {any[]} selectedRowIds Selected Row IDs
   */
    const deleteSelectedRowData = async (selectedRowIds: any[]) => {
        let deleteRowsToastId;

        try {
            // Delete selected newly inserted rows
            const oldDataRowIds: number[] = selectedRowIds.filter(rowId => !rowId.toString().startsWith("new_")).map(rowId => parseInt(rowId));
            setInsertedRows((insertedRows: any) => removeInsertedRowsById(insertedRows, selectedRowIds));

            if (oldDataRowIds.length) {
                deleteRowsToastId = toast.loading("Deleting selected rows...", {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions("loading"),
                });

                const deleteAPIResponse: AxiosResponse<{
                    data?: string;
                    error?: string;
                }> = await fetchData(`/lot-size-groups`, {
                    method: 'DELETE',
                    data: {
                        ids: oldDataRowIds
                    },
                });

                toast.dismiss({
                    id: deleteRowsToastId,
                    containerId: TOAST_CONTAINER_ID,
                });

                if (deleteAPIResponse.data.data) {
                    setCellEditTracks({});
                    setInsertedRows({});

                    dispatchFn(planningParametersTabActions.setTabApiDataFetched({
                        [LOT_SIZE_GROUPS_TAB_ID]: false
                    }));

                    toast.success(deleteAPIResponse.data.data, {
                        containerId: TOAST_CONTAINER_ID,
                        ...getToastOptions("success"),
                    });

                } else {
                    toast.error(deleteAPIResponse.data.error, {
                        containerId: TOAST_CONTAINER_ID,
                        ...getToastOptions("error"),
                    });
                }
            } else {
                const selectedRows: any[] | undefined = agGridRef.current?.api.getSelectedRows();
                agGridRef.current?.api.applyTransaction({
                    remove: selectedRows
                });
            }
        } catch (error: any) {
            console.error(error);

            if (deleteRowsToastId) {
                toast.error("Failed to delete the selected rows", {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions("error"),
                });
            }
        }
    };

    /**
     * Updates cell values by using part site source update API
     * @param {any} cellEditTracks Cell edits in the grid
     * @param {any} insertedRows Newly inserted rows
     */
    const updateCellValues = async (
        cellEditTracks: any,
        insertedRows: any
    ): Promise<void> => {
        try {
            const updateToastId = toast.loading("Updating Lot Size Group data...", {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions("loading"),
            });

            const updateAPIResponse: AxiosResponse<{
                data?: string;
                error?: string;
            }> = await fetchData(`/lot-size-groups`, {
                method: 'PUT',
                data: {
                    update_data: cellEditTracks,
                    insert_data: insertedRows
                }
            });

            toast.dismiss({
                id: updateToastId,
                containerId: TOAST_CONTAINER_ID,
            });

            if (updateAPIResponse.data.data) {
                setCellEditTracks({});
                setInsertedRows({});

                dispatchFn(planningParametersTabActions.setTabApiDataFetched({
                    [LOT_SIZE_GROUPS_TAB_ID]: false
                }));

                dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'lotSizeGroups', value: false }));

                toast.success(updateAPIResponse.data.data, {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions("success"),
                });

            } else {
                toast.error(updateAPIResponse.data.error, {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions("error"),
                });
            }
        } catch (error: any) {
            console.error(error);
            toast.error("Error in updating the Lot Size Group data", {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions("error"),
            });
        }
    };

    /**
     * Event handler for save button click
     * @param {any} event Save button click event data
     */
    const onUpdateCellValuesHandler = async (event: any): Promise<void> => {
        try {
            const updatedNewRowData: any = {};
            for (let key in insertedRows) {
                if (Object.keys(insertedRows[key]).length) {
                    updatedNewRowData[key] = insertedRows[key];
                }
            }

            if (
                Object.keys(cellEditTracks).length ||
                Object.keys(updatedNewRowData).length
            ) {
                updateCellValues(cellEditTracks, updatedNewRowData);

            }
        } catch (error: Error | any) {
            console.error(`Error: ${error}`);
        }
    };

    /**
     * Event handler for reset button click
     * @param {any} params Reset button click event data
     */
    const resetCellEdits = async (event: any): Promise<void> => {
        try {
            const resetConsent: boolean = window.confirm(
                "Do you want to reset these changes?"
            );

            if (resetConsent) {
                setCellEditTracks({});
                setChangeTrackState(false);
                setGridRowDataCopy(JSON.parse(JSON.stringify([...gridRowData])));
                dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({
                    key: 'lotSizeGroups',
                    value: false
                }));
            }
        } catch (error: Error | any) {
            console.error(`Error: ${error}`);
        }
    };

    /**
     * Inserts a new row into the grid
     */
    const addRowToGrid = (): void => {
        const newRowId: string = `new_${newRowCount.toString()}`;
        agGridRef.current!.api!.applyTransaction({
            add: [{ id: newRowId }],
            addIndex: 0,
        });
        setInsertedRows((insertedRows: any) => {
            return {
                ...insertedRows,
                [newRowId]: {}
            };
        });
        setChangeTrackState(true);
        setNewRowCount((rowCount) => (rowCount + 1));
        dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'lotSizeGroups', value: true }));
    }

    /**
     * Deletes selected rows from the grid
     */
    const deleteSelectedRowsFromGrid = (): void => {
        deleteSelectedRowData(selectedRowIds);
        setSelectedRowIds([]);
        setEnableDelButton(false);
    };

    useEffect(() => {
        if (
            !tabApiDataFetched &&
            activeLowerSubTabId === LOT_SIZE_GROUPS_TAB_ID
        ) {
            fetchLotSizeGroups({
                site_codes: selectedSiteSlicers
            });
        }
    }, [
        fetchLotSizeGroups,
        tabApiDataFetched,
        activeLowerSubTabId,
        selectedSiteSlicers,
        isSiteMasterUpdated,
        isLotSizeOptimizationPolicyUpdated
    ]);

    useEffect(() => {
        if (selectedRowIds.length > 0) {
            setEnableDelButton(true);
        } else {
            setEnableDelButton(false);
        }
    }, [selectedRowIds]);

    useEffect(() => {
        if (
            Object.keys(insertedRows).length === 0 &&
            Object.keys(cellEditTracks).length === 0
        ) {
            setChangeTrackState(false);
        }
    }, [insertedRows, cellEditTracks]);

    return (
        <>
            <div className="row mb-2">
                <div className="col offset-8 col-4 text-end">
                    <EditableGridActions
                        disableIf={{
                            resetBtn: !changeTrackState,
                            saveBtn: !changeTrackState,
                            deleteRowBtn: !enableDelButton
                        }}
                        onClick={{
                            resetBtn: resetCellEdits,
                            saveBtn: onUpdateCellValuesHandler,
                            addRowBtn: addRowToGrid,
                            deleteRowBtn: deleteSelectedRowsFromGrid
                        }}
                    />
                </div>
            </div>

            <div
                className="ag-theme-balham mt-2"
                style={{
                    height: props.gridHeight,
                    maxHeight: props.gridHeight,
                    width: "100%",
                    overflowX: "auto",
                    overflowY: "auto",
                }}
            >
                <AgGridReact
                    ref={agGridRef}
                    rowData={gridRowDataCopy}
                    columnDefs={gridColDef}
                    defaultColDef={{
                        width: 200,
                        editable: true,
                        ...(AG_GRID_DEFAULT_COL_DEF as any),
                    }}
                    rowSelection={"multiple"}
                    onCellEditingStopped={onCellEditingStoppedHandler}
                    gridOptions={{
                        ...(AG_GRID_OPTIONS as any),
                        onSelectionChanged: onSelectionChangedHandler,
                        getRowStyle: (params: any) => newGridRowStyleHandler(params, "id")
                    }}
                    modules={AG_GRID_MODULES}
                />
            </div>
        </>
    );
};

export default LotSizeGroupsGrid;
