import { AgGridReact } from "ag-grid-react";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useFetch from "../../../hooks/useFetchMSAL";
import { LotSizeOptimizationTabFetchAPI } from "../../../models/APIResponses.model";
import PlannedSupplyReceipt from "../../../models/ReplenishmentPlan/PlannedSupplyReceipt.model";
import {
  AG_GRID_DEFAULT_COL_DEF,
  AG_GRID_MODULES,
  LSO_PLANNED_SUPPLY_RECEIPTS_TAB_ID,
  TOAST_CONTAINER_ID,
} from "../../../shared/constants";
import {
  agGridNumberFormatter,
  getToastOptions,
} from "../../../shared/functions";
import { RootState } from "../../../store";
import { lotSizeOptimizationTabActions } from "../../../store/slices/lot-size-optimization-tab-slice";
import "../../../styles.css";
import "../ReplenishmentPlanTab/PlannedSupplyReceiptsGrid.css";
import { LSOSelectedSlicerModel } from "../../../models/LotSizeOptimization/LSOSlicer.model";

const LSOPlannedSupplyReceiptsGrid = (props: { gridHeight: string }) => {
  const [, fetchData] = useFetch([]);
  // States
  const [gridRowData, setGridRowData] = useState<PlannedSupplyReceipt[]>([]);
  const agGridRef = useRef<AgGridReact>(null);
  const [, setSelectedRows] = useState<any[]>([]);
  
  // Variables
  const gridColDef: any[] = [
    {
      field: "site",
      tooltipField: "site",
      headerTooltip: "Site Name",
      cellDataType: "text",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      width: 200,
      headerName: "Site Name",

    },
    {
      field: "sku",
      tooltipField: "sku",
      headerTooltip: "SKU Code",
      cellDataType: "text",
      headerName: "SKU Code",
    },
    {
      field: "supply_id",
      tooltipField: "supply_id",
      headerTooltip: "Supply ID",
      cellDataType: "number",
      cellClass: "ag-right-aligned-cell",
      headerClass: "ag-left-aligned-cell",
      headerName: "Planned Supply ID",
    },
    {
      field: "po_number",
      tooltipField: "po_number",
      headerTooltip: "PO Number",
      cellDataType: "text",
      headerName: "PO Number",
    },
    {
      field: "recommended_release_date",
      tooltipField: "recommended_release_date",
      headerTooltip: "Projected Release Date",
      cellDataType: "text",
      headerName: "Projected Release Date",
    },
    {
      field: "supply_type",
      tooltipField: "supply_type",
      headerTooltip: "Supply Type",
      cellDataType: "text",
      headerName: "Supply Type",
      width: 150,
    },
    {
      field: "expected_receipt_date",
      tooltipField: "expected_receipt_date",
      headerTooltip: "Expected Receipt Date",
      cellDataType: "text",
      headerName: "Expected Receipt Date",
    },
    {
      field: "quantity",
      headerTooltip: "Quantity",
      cellDataType: "number",
      cellClass: "ag-right-aligned-cell",
      headerClass: "ag-left-aligned-cell",
      headerName: "Quantity",
      tooltipValueGetter: (params: any) =>
        agGridNumberFormatter(params.data.quantity, 2),
      valueFormatter: (params: any) =>
        agGridNumberFormatter(params.data.quantity, 2),
    },
    {
      field: "recommended_release_date",
      tooltipField: "recommended_release_date",
      headerTooltip: "Recommended Release Date",
      cellDataType: "text",
      headerName: "Recommended Release Date",
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
    },
    {
      field: "recommended_date",
      tooltipField: "recommended_date",
      headerTooltip: "Recommended Date",
      cellDataType: "text",
      headerName: "Recommended Date",
      suppressHeaderMenuButton: true,
      sortable: false,
    },
    {
      field: "is_recommended_release_date_rejected",
      tooltipField: "is_recommended_release_date_rejected",
      headerTooltip: "Recommended Release Date Rejected",
      cellDataType: "boolean",
      headerName: "Recommended Release Date Rejected",
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
    },
    
  ];

  const isRowSelectable = useCallback((rowNode:any) => {
    return rowNode.data ? rowNode.data.recommended_date!=null : false;
  }, []);

  // Redux variables
  const dispatchFn = useDispatch();
  const isSiteMasterUpdated = useSelector(
    (state: RootState) => state.masterTables.isSiteMasterUpdated
  );
  const isPartMasterUpdated = useSelector(
    (state: RootState) => state.masterTables.isPartMasterUpdated
  );
  const tabApiDataFetched: boolean = useSelector(
    (state: RootState) => state.lotSizeOptimizationTab.tabApiDataFetched
  )[LSO_PLANNED_SUPPLY_RECEIPTS_TAB_ID];

  
  const activeSubTabId: string = useSelector(
    (state: RootState) => state.lotSizeOptimizationTab.activeSubTabId
  );
  
  const selectedSlicers: LSOSelectedSlicerModel = useSelector(
    (state: RootState) => state.lotSizeOptimizationTab.selectedSlicers
  );

  const fetchPlannedSupplyReceipts = useCallback(async () => {
    const fetchingDataToastId = toast.loading(
      "Fetching planned supply receipts data...",
      {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      }
    );
    try {
      const fetchAPIResponse: AxiosResponse<
        LotSizeOptimizationTabFetchAPI<PlannedSupplyReceipt[]>
      > = await fetchData(`/lsop-planned-supply-receipts`, {
        method: "POST",
        data: {
          site_codes: selectedSlicers.site_codes,
          group_policy_name: selectedSlicers.group_policy_name,
          number_of_weeks: selectedSlicers.number_of_weeks
        },
      });

      toast.dismiss({
        id: fetchingDataToastId,
        containerId: TOAST_CONTAINER_ID,
      });

      if (fetchAPIResponse.data.data) {
        setGridRowData(fetchAPIResponse.data.data.result);
        dispatchFn(
          lotSizeOptimizationTabActions.setSlicers(fetchAPIResponse.data.data.slicers)
        );
        dispatchFn(
          lotSizeOptimizationTabActions.setTabApiDataFetched({
            [LSO_PLANNED_SUPPLY_RECEIPTS_TAB_ID]: true,
          })
        );
      } else {
        toast.error("Error in fetching planned supply receipts data", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    } catch (error: Error | any) {
      console.error(`Request Error: ${error}`);
      toast.dismiss({
        id: fetchingDataToastId,
        containerId: TOAST_CONTAINER_ID,
      });
      toast.error(error?.response?.data?.error || "Error in fetching data", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  }, [dispatchFn, fetchData, selectedSlicers]);

  // Initial run
  useEffect(() => {
    if (
      !tabApiDataFetched &&
      activeSubTabId === LSO_PLANNED_SUPPLY_RECEIPTS_TAB_ID
    ) {
      fetchPlannedSupplyReceipts();
    }
  }, [
    tabApiDataFetched,
    activeSubTabId,
    selectedSlicers,
    isSiteMasterUpdated,
    isPartMasterUpdated,
    fetchPlannedSupplyReceipts,
  ]);


  const downloadReports = useCallback(() => {
    const gridApi = agGridRef.current?.api;
    if (!gridApi) return;

    const originalColumnDefs = gridApi.getColumnDefs();

    // Update column definitions to hide 'Actions' column
    const updatedColumnDefs = originalColumnDefs?.map((colDef: any) => {
      if (colDef.headerName === "Status") {
        return { ...colDef, hide: true };
      }
      return colDef;
    });

    // Update grid options to apply changes
    gridApi.updateGridOptions({
      columnDefs: updatedColumnDefs,
    });

    // Retrieve all rows
    const allRows: any = [];
    gridApi.forEachNode((node: any) => allRows.push(node.data));

    // Filter rows where the status column value is 'Calculated'
    const filteredRows = allRows.filter(
      (row: any) =>
        row.is_recommended_release_date_rejected === true &&
        row.supply_type === "Reschedulable"
    );

    // Temporarily set the grid data to filteredRows for export
    gridApi.updateGridOptions({
      rowData: filteredRows,
    });

    // Export data as Excel
    gridApi.exportDataAsExcel({
      sheetName: "Filtered Data",
    });

    // Restore original data after export
    gridApi.updateGridOptions({
      rowData: gridRowData,
    });
  }, [gridRowData]);

  const onSelectionChanged = () => {
    const selectedRows1 = agGridRef.current!.api!.getSelectedRows();
    let rowIdList:any[] = selectedRows1.map((e)=> {
      let idPO:any = {}
      idPO[e.id] = e.po_number;
      return idPO
    });

    // selectedRows1.forEach((el) =>{ 
    //   let dict:any = {};
    //   dict[+el.id] = !el.is_recommended_release_date_rejected;
    //   rowList.push(dict)
    // });

    setSelectedRows(rowIdList);
  };



  return (
    <>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-sm btn-custom-primary mx-2"
          disabled={gridRowData && !gridRowData.length}
          onClick={downloadReports}
        >
          <MdOutlineFileDownload /> &nbsp;Download Report
        </button>
      </div>

      <div
        className="ag-theme-balham mt-2"
        style={{
          height: props.gridHeight,
          maxHeight: props.gridHeight,
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <AgGridReact
          ref={agGridRef}
          rowData={gridRowData}
          columnDefs={gridColDef}
          isRowSelectable={isRowSelectable}
          gridOptions={{
            rowHeight: 22,
            suppressRowHoverHighlight: true,
            enableRangeSelection: true,
            suppressMovableColumns: true,
            suppressRowDrag: true,
            clipboardDelimiter: ",",
            getContextMenuItems: (params: any) => [
              "copy",
              "copyWithHeaders",
              "excelExport",
            ],
            onSelectionChanged: onSelectionChanged,
          }}
          
          defaultColDef={{
            width: 100,
            ...(AG_GRID_DEFAULT_COL_DEF as any),
          }}
          modules={AG_GRID_MODULES}
          rowSelection={"multiple"}
        />

      </div>
    </>
  );
};

export default LSOPlannedSupplyReceiptsGrid;
