import { AgGridReact } from "ag-grid-react";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useFetch from "../../../hooks/useFetchMSAL";
import { CustomerWarehouseMappingTabFetchAPI } from "../../../models/APIResponses.model";
import CustomerWarehouseMapping from "../../../models/PlanningParameters/CustomerWarehouseMapping.model";
import PlanningParametersCellEditTrack from "../../../models/PlanningParameters/PlanningParametersCellEditTrack.model";
import { AG_GRID_DEFAULT_COL_DEF, AG_GRID_MODULES, AG_GRID_OPTIONS, CUSTOMER_WAREHOUSE_MAPPING_TAB_ID, TOAST_CONTAINER_ID } from "../../../shared/constants";
import { getToastOptions, newGridRowStyleHandler, removeInsertedRowsById } from "../../../shared/functions";
import { RootState } from "../../../store";
import { planningParametersTabActions } from "../../../store/slices/planning-parameters-tab-slice";
import EditableGridActions from "../../UI/EditableGridActions/EditableGridActions";

const CustomerWarehouseMappingGrid = (props: any) => {
  const [, fetchData] = useFetch([]);
  const agGridRef = useRef<AgGridReact>(null);
  const [agRowData, setAgRowData] = useState<CustomerWarehouseMapping[]>([]);
  const [agRowDataCopy, setAgRowDataCopy] = useState<CustomerWarehouseMapping[]>([]);
  const [customerCodeValues, setCustomerCodeValues] = useState<string[]>([]);
  const [siteCodeValues, setSiteCodeValues] = useState<string[]>([]);
  const [changeTrackState, setChangeTrackState] = useState(false);
  const [enableDelButton, setEnableDelButton] = useState(false);
  const [cellEditTracks, setCellEditTracks] =
    useState<PlanningParametersCellEditTrack>({});
  const dispatchFn = useDispatch();
  const [insertedRows, setInsertedRows] = useState<any>({});
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [newRowCount, setNewRowCount] = useState<number>(1);
  const [selectedRowIds, setSelectedRowIds] = useState<any[]>([]);
  const [siteMapping, setSiteMapping] = useState<{ [key: string]: string; }>({});
  const [customerMapping, setCustomerMapping] = useState<{ [key: string]: string; }>({});

  const activeUpperSubTabId: string = useSelector((state: RootState) => state.planningParametersTab.activeSubTabId.upper);
  const tabApiDataFetched: boolean = useSelector((state: RootState) => state.planningParametersTab.tabApiDataFetched)[CUSTOMER_WAREHOUSE_MAPPING_TAB_ID];
  const selectedGridSlicers = useSelector(
    (state: RootState) => state.planningParametersTab.selectedGridSlicers
  );
  const isOrderPolicyUpdated = useSelector(
    (state: RootState) => state.planningParametersTab.isOrderPolicyUpdated
  );
  const isSafetyStockPolicyUpdated = useSelector(
    (state: RootState) =>
      state.planningParametersTab.isSafetyStockPolicyUpdated
  );

  const isPartMasterUpdated = useSelector(
    (state: RootState) => state.masterTables.isPartMasterUpdated
  );
  const isSiteMasterUpdated = useSelector(
    (state: RootState) => state.masterTables.isSiteMasterUpdated
  );
  const isSourceMasterUpdated = useSelector(
    (state: RootState) => state.masterTables.isSourceMasterUpdated
  );


  const gridColDef: any[] = [
    {
      headerName: "Customer Name",
      field: "customer_code",
      cellDataType: "text",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: { values: customerCodeValues },
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    {
      headerName: "Site Name",
      field: "site_code",
      cellDataType: "text",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: { values: siteCodeValues },
    },

  ];
  const defaultColDef = useMemo(() => {
    return {
      width: 140,
      editable: true,
      ...(AG_GRID_DEFAULT_COL_DEF as any),
    };
  }, []);

  const fetchCustomerWarehouseMapping = useCallback(
    async (body: any): Promise<void> => {

      const fetchingDataToastId = toast.loading("Fetching Customer Warehouse Mapping data...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });
      try {
        const fetchAPIResponse: AxiosResponse<CustomerWarehouseMappingTabFetchAPI> =
          await fetchData(`/get-customer-warehouse-mapping`,
            {
              method: 'POST',
              data: body,
            });

        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (fetchAPIResponse.data.data) {
          setAgRowData(fetchAPIResponse.data.data.result);
          setAgRowDataCopy(
            JSON.parse(JSON.stringify(fetchAPIResponse.data.data.result))
          );
          setCustomerCodeValues(
            fetchAPIResponse.data.data.cell_dropdowns.customer_code
          );
          setSiteCodeValues(
            fetchAPIResponse.data.data.cell_dropdowns.site_code
          );
          setSiteMapping(fetchAPIResponse.data.data.site_mapping)
          setCustomerMapping(fetchAPIResponse.data.data.customer_mapping)

          dispatchFn(planningParametersTabActions.setTabApiDataFetched({
            [CUSTOMER_WAREHOUSE_MAPPING_TAB_ID]: true
          }));

        } else {
          toast.error(fetchAPIResponse.data.error, {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } catch (error: Error | any) {
        console.error(`Request Error: ${error.name}`);
        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });
        toast.error("Error in fetching Customer Warehouse mapping data", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    },
    [
      dispatchFn,
      fetchData,
    ]
  );

  const updateCellValues = async (
    cellEditTracks: any,
    insertedRows: any
  ): Promise<void> => {
    try {
      const updateToastId = toast.loading("Updating Customer Warehouse Mapping data...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });

      const updateReqResponse: AxiosResponse<{ 
        data: string | null; 
        error: string | null; 
      }> = await fetchData(`/update-customer-warehouse-mapping`, {
        method: 'PUT',
        data: {
          update_data: cellEditTracks,
          insert_data: insertedRows
        }
      });

      toast.dismiss({
        id: updateToastId,
        containerId: TOAST_CONTAINER_ID,
      });

      if (updateReqResponse.data) {
        setCellEditTracks({});
        setInsertedRows({});

        dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'customerWarehouseMapping', value: false }));

        toast.success(updateReqResponse.data.data, {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("success"),
        });

        dispatchFn(planningParametersTabActions.setTabApiDataFetched({
          [CUSTOMER_WAREHOUSE_MAPPING_TAB_ID]: false
        }));

        setIsDataUpdated(true);

      } else {
        toast.error("Error in updating the Customer Warehouse mapping data", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    } catch (error: AxiosError | any) {
      console.error(error);
      toast.error("Error in updating the Customer Warehouse mapping data", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  };

  const deleteSelectedRowData = async (selectedRowIds: any[]) => {
    let deleteRowsToastId;

    try {
      // Delete selected newly inserted rows
      const oldDataRowIds: any[] = selectedRowIds.filter(rowId => !rowId.toString().startsWith("new_"));
      setInsertedRows((insertedRows: any) => removeInsertedRowsById(insertedRows, selectedRowIds));

      if (oldDataRowIds.length) {
        deleteRowsToastId = toast.loading("Deleting selected rows...", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("loading"),
        });

        const deleteReqResponse: AxiosResponse<{
          data: string | null;
          error: string | null;
        }> = await fetchData(`/delete-customer-warehouse-mapping`, {
          method: 'DELETE',
          data: { row_ids_to_delete: oldDataRowIds },
        });

        toast.dismiss({
          id: deleteRowsToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (deleteReqResponse.data.data) {
          setCellEditTracks({});
          setInsertedRows({});

          toast.success(deleteReqResponse.data.data, {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("success"),
          });
          dispatchFn(planningParametersTabActions.setTabApiDataFetched({
            [CUSTOMER_WAREHOUSE_MAPPING_TAB_ID]: false
          }));

          setIsDataUpdated((prevDataUpdate) => !prevDataUpdate);

        } else {
          toast.error(deleteReqResponse.data.error, {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } else {
        const selectedRows: any[] | undefined = agGridRef.current?.api.getSelectedRows();
        agGridRef.current?.api.applyTransaction({
          remove: selectedRows
        });
      }
    } catch (error: AxiosError | any) {
      console.error(error);

      if (deleteRowsToastId) {
        toast.error("Failed to delete the selected rows", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    }
  };

  const onCellEditingStoppedHandler = (params: any) => {
    try {
      var id: string = params.node.data.id;
      const colName = params.column.colId;

      if (id !== undefined && id.toString().startsWith("new_")) {
        setInsertedRows((prev: any) => {
          let newData = prev;
          let newRow = newData[id] ? newData[id] : {};

          switch (colName) {
            case "site_code":
              newRow["site_code"] = siteMapping[params.newValue];
              break;
            case "customer_code":
              newRow["customer_code"] = customerMapping[params.newValue];
              break;
            default:
              newRow[colName] = params.newValue;
          }

          newData[id] = newRow;
          return newData;
        });
      } else {
        if (params.oldValue !== params.newValue && params.newValue !== undefined) {
          let currentRowNode = agGridRef.current!.api!.getRowNode(params.node.id);

          setCellEditTracks((prev) => {
            let newTrackEdits = prev;
            let cellEditTrack = newTrackEdits[id] ? newTrackEdits[id] : {};

            switch (colName) {
              case "site_code":
                cellEditTrack["site_code"] = siteMapping[params.newValue];
                break;
              case "customer_code":
                cellEditTrack["customer_code"] = customerMapping[params.newValue];
                break;
              default:
                cellEditTrack[colName] = params.newValue;
            }

            newTrackEdits[id] = cellEditTrack;
            return newTrackEdits;
          });
          dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'partSiteSource', value: true }));

          setChangeTrackState(true);
          agGridRef.current!.api!.applyTransaction({
            update: [currentRowNode?.data],
          });
        } else if (params.newValue === params.oldValue) {
          dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'partSiteSource', value: false }));

        }
      }

    } catch (error: any) {
      console.error(`Error: ${error.name}`);
    }
  };

  const onSelectionChangedHandler = () => {
    const selectedRows = agGridRef.current!.api!.getSelectedRows();
    const selectedRowIds: any[] = selectedRows.map((el) => el.id);
    setSelectedRowIds(selectedRowIds);
  };

  const resetCellEdits = async (event: any): Promise<void> => {
    try {
      const resetConsent: boolean = window.confirm(
        "Do you want to reset these changes?"
      );

      if (resetConsent) {
        setCellEditTracks({});
        setChangeTrackState(false);
        setAgRowDataCopy(JSON.parse(JSON.stringify([...agRowData])));
        dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'customerWarehouseMapping', value: false }));
      }
    } catch (error: Error | any) {
      console.error(`Error: ${error.name}`);
    }
  };

  const onUpdateCellValuesHandler = async (event: any): Promise<void> => {
    try {
      const updatedNewRowData: any = {};
      for (let key in insertedRows) {
        if (Object.keys(insertedRows[key]).length) {
          updatedNewRowData[key] = insertedRows[key];
        }
      }

      if (
        Object.keys(cellEditTracks).length ||
        Object.keys(updatedNewRowData).length
      ) {
        updateCellValues(cellEditTracks, updatedNewRowData);

      }
    } catch (error: Error | any) {
      console.error(`Error: ${error.name}`);
    }
  };

  const addRowToGrid = (): void => {
    const newRowId: string = `new_${newRowCount.toString()}`;
    agGridRef.current!.api!.applyTransaction({
      add: [{ id: newRowId }],
      addIndex: 0,
    });
    setInsertedRows((insertedRows: any) => {
      return {
        ...insertedRows,
        [newRowId]: {}
      };
    });
    setChangeTrackState(true);
    setNewRowCount((rowCount) => (rowCount + 1));
    dispatchFn(planningParametersTabActions.setIsPlanningParametersTabEdited({ key: 'customerWarehouseMapping', value: true }));
  }

  const deleteSelectedRowsFromGrid = (): void => {
    deleteSelectedRowData(selectedRowIds);
    setSelectedRowIds([]);
    setEnableDelButton(false);
  }

  useEffect(() => {
    if (
      !tabApiDataFetched &&
      activeUpperSubTabId === CUSTOMER_WAREHOUSE_MAPPING_TAB_ID
    ) {
      fetchCustomerWarehouseMapping(selectedGridSlicers);
    }
  }, [
    fetchCustomerWarehouseMapping,
    tabApiDataFetched,
    activeUpperSubTabId,
    selectedGridSlicers,
    isDataUpdated,
    isOrderPolicyUpdated,
    isSafetyStockPolicyUpdated,
    isPartMasterUpdated,
    isSiteMasterUpdated,
    isSourceMasterUpdated
  ]);

  useEffect(() => {
    if (selectedRowIds.length > 0) {
      setEnableDelButton(true);
    } else {
      setEnableDelButton(false);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (
      Object.keys(insertedRows).length === 0 &&
      Object.keys(cellEditTracks).length === 0
    ) {
      setChangeTrackState(false);
    }
  }, [insertedRows, cellEditTracks]);

  const gridOptions = {
    ...(AG_GRID_OPTIONS as any),
    onSelectionChanged: onSelectionChangedHandler,
    getRowStyle: (params: any) => newGridRowStyleHandler(params, "id")
  };

  return (
    <>
      <div className="row mb-2">
        <div className="col offset-8 col-4 text-end">
          <EditableGridActions
            disableIf={{
              resetBtn: !changeTrackState,
              saveBtn: !changeTrackState,
              deleteRowBtn: !enableDelButton
            }}
            onClick={{
              resetBtn: resetCellEdits,
              saveBtn: onUpdateCellValuesHandler,
              addRowBtn: addRowToGrid,
              deleteRowBtn: deleteSelectedRowsFromGrid
            }}
          />
        </div>
      </div>
      <div
        className="ag-theme-balham mt-2"
        style={{
          height: props.gridHeight,
          maxHeight: props.gridHeight,
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <AgGridReact
          ref={agGridRef}
          rowData={agRowDataCopy}
          columnDefs={gridColDef}
          defaultColDef={defaultColDef}
          rowSelection={"multiple"}
          onCellEditingStopped={onCellEditingStoppedHandler}
          gridOptions={gridOptions}
          modules={AG_GRID_MODULES}
        />
      </div>
    </>
  )
}
export default CustomerWarehouseMappingGrid