import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { toast } from "react-toastify"
import { AG_GRID_MODULES, AG_GRID_OPTIONS, CUTS_OBSERVER, TOAST_CONTAINER_ID } from "../../../shared/constants"
import { getToastOptions } from "../../../shared/functions"
import { AxiosError, AxiosResponse } from "axios"
import useFetch from "../../../hooks/useFetchMSAL"
import { ReplPlanSelectedSlicerModel } from "../../../models/ReplenishmentPlan/ReplPlanSlicer.model"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { controllTowerTabActions } from "../../../store/slices/control-tower-tab-slice"
import { AgGridReact } from "ag-grid-react"
import { ColumnsToolPanelModule, FiltersToolPanelModule, SetFilterModule } from "ag-grid-enterprise"

const ControlTowerGrid = (props: {
    gridHeight: string;
}) => {
    const [{ data, loading, error }, fetchData] = useFetch([]);
    const [gridCellEdits, setGridCellEdits] = useState<{
        [key: string]: {
            [key: string]: number;
        };
    }>({});
    const agGridRef = useRef<AgGridReact>(null);
    const [gridRowDataCopy, setGridRowDataCopy] = useState<[]>([]);
    const [gridColDef, setGridColDef] = useState<any>([]);
    const dispatchFn = useDispatch();
    const tabApiDataFetched: boolean = useSelector((state: RootState) => state.controllTowerTab.tabApiDataFetched)[CUTS_OBSERVER];
    const activeUpperSubTabId: string = useSelector((state: RootState) => state.controllTowerTab.activeSubTabId.upper);
    const selectedSlicers = useSelector((state: RootState) => state.controllTowerTab.selectedGridSlicers);
    const applyFilter = useSelector((state: RootState) => state.controllTowerTab.applyFilter);

    const colNames = (el:string) => {
        if(el === "part_name")
            return "Part Name"
        else if(el === "site_name")
            return "Site Name"
        else if(el === "cuts")
            return "Cuts"
    }

    const generateColDef = (rowData:any) =>{
        const coldefs:any = [];
        Object.keys(rowData).forEach(e =>{
            let obj:any = {
                headerName: colNames(e),
                field: e,
                cellDataType: e === 'demand_date' ? 'text' : e==='cuts'?'number':'text',
                rowGroup: e === 'part_name'||e === 'site_name' ? true:false,
                hide: e === 'part_name'||e === 'site_name' ? true:false,
                pinned: e === 'part_name'||e === 'site_name' ? "left":"",
                pivot: e === 'demand_date'? true:false,
                aggFunc: e === 'cuts' ? "sum": "",
                cellClass: (e === "cuts") ? "ag-right-aligned-cell" : "ag-left-aligned-cell",
                headerClass: "ag-left-aligned-cell",
                cellRenderer: (p:any) => {
                    if(e==="cuts" && p.value === 0)
                    {return " "}
                    else{return p.value}
                },
                width: 180,
            };
            if(e === 'demand_date'){
                obj['pivotComparator'] = (valueA:any, valueB:any) => {
                    const dateA:any = new Date(valueA);
                    const dateB:any = new Date(valueB);
                    return dateA - dateB; // Sort in ascending order
                  }
            }
            coldefs.push(obj)

        });

        return coldefs;
    }


    /**
     * Async function to fetch data from replenishment plan summary API
     */
    const fetchControllTower = useCallback(async (): Promise<void> => {
        const fetchingDataToastId = toast.loading('Fetching control tower grid data...', {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions('loading')
        });

        try {
            const fetchAPIResponse: AxiosResponse = await fetchData("/get-control-tower-data", {
                method: "POST",
                data: {
                    site_code: selectedSlicers.location_names,
                    part_code: selectedSlicers.part_names,
                    number_of_weeks: selectedSlicers.number_of_weeks
                }
            });

            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });

            if (fetchAPIResponse.data.data) {
                
                if(fetchAPIResponse.data.data.length>0){
                    dispatchFn(controllTowerTabActions.setGridRowData(JSON.parse(JSON.stringify(fetchAPIResponse.data.data))));
                    setGridRowDataCopy(JSON.parse(JSON.stringify(fetchAPIResponse.data.data)));
                    setGridColDef((prevColDef:any) => generateColDef(JSON.parse(JSON.stringify(fetchAPIResponse.data.data[0]))));
                }else{
                    dispatchFn(controllTowerTabActions.setGridRowData([]));
                    setGridRowDataCopy([]);
                    setGridColDef((prevColDef:any) => generateColDef(JSON.parse(JSON.stringify({
                        "part_name": "CT-01",
                        "site_name": "OL Vancouver",
                        "demand_date": 1719100800000,
                        "cuts": 0
                    }))));
                }

                dispatchFn(controllTowerTabActions.setSlicers(JSON.parse(JSON.stringify(fetchAPIResponse.data.slicers))));
                dispatchFn(controllTowerTabActions.setMappings(JSON.parse(JSON.stringify(fetchAPIResponse.data.part_site_mapping))));
                dispatchFn(
                    controllTowerTabActions.setTabApiDataFetched({
                      [CUTS_OBSERVER]: true,
                    })
                  );
                // dispatchFn(controllTowerTabActions.setApplyFilter(false));
                
                // gridCellHighlights = { ...fetchAPIResponse.data.data.cell_highlights };
                
            } else {
                toast.error('Error in fetching summary data', {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions('error')
                });
            }

        } catch (error: Error | AxiosError | any) {
            console.error("Error ", error)
            console.error(`Request Error: ${error.name}`);
            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });
            toast.error((
                error?.response?.data?.error ||
                'Error in fetching data'
            ), {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions('error')
            });
        }
    }, [selectedSlicers, TOAST_CONTAINER_ID, fetchData, dispatchFn, generateColDef]);

    useEffect(()=>{
        if (
            !tabApiDataFetched &&
            activeUpperSubTabId === CUTS_OBSERVER
          ) {
        fetchControllTower();
          }
    },[applyFilter, tabApiDataFetched, activeUpperSubTabId])


    return (
        <>
            
            <div className="ag-theme-balham mt-2" style={{ height: props.gridHeight, maxHeight: props.gridHeight, width: '100%', overflowX: 'auto', overflowY: 'auto' }}>
                <AgGridReact
                    ref={agGridRef}
                    rowData={gridRowDataCopy}
                    columnDefs={gridColDef}
                    groupHideOpenParents={true}
                    gridOptions={{
                        ...(AG_GRID_OPTIONS as any),
                        suppressMenuHide: true,
                        suppressAggFuncInHeader: true,
                        // getRowStyle: gridRowStyleHandler
                    }}
                    defaultColDef={{
                        sortable: false
                    }}
                    modules={[
                        ...AG_GRID_MODULES,
                        ColumnsToolPanelModule,
                        FiltersToolPanelModule,
                        SetFilterModule
                    ]}
                    autoGroupColumnDef={{
                        cellRendererParams: {
                            suppressCount: true
                        }
                    }}
                    pivotMode={true}
                    animateRows={true}
                    groupDisplayType="multipleColumns"
                    
                    // onCellValueChanged={cellValueChangeHandler}
                    // processCellForClipboard={processCellForClipboard}
                />
            </div>
        </>
    )
}
export default ControlTowerGrid