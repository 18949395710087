import { ChangeEvent, useEffect, useState } from "react";
import { BiReset } from "react-icons/bi";
import { FaLayerGroup } from "react-icons/fa";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { LuWarehouse } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";

import { LSOSelectedSlicerModel, LSOSlicerDropdownModel } from "../../../models/LotSizeOptimization/LSOSlicer.model";
import { RootState } from "../../../store";
import { lotSizeOptimizationTabActions } from "../../../store/slices/lot-size-optimization-tab-slice";
import Dropdown from "../../UI/Dropdown/Dropdown";
import { getDefaultLSOSlicers } from "../../../shared/functions";

const LotSizeOptimizationSlicers = () => {
  // Redux variables
  const dispatchFn = useDispatch();
  const tempSelectedSlicers: LSOSelectedSlicerModel = useSelector((state: RootState) => state.lotSizeOptimizationTab.tempSelectedSlicers);
  const slicers: LSOSlicerDropdownModel = useSelector((state: RootState) => state.lotSizeOptimizationTab.slicers);

  // States
  const [partCode, setPartCode] = useState(tempSelectedSlicers.part_code);
  const [filteredSiteSlicer, setFilteredSiteSlicer] = useState<{ name: string; value: string; }[]>([]);

  const numOfWeeksChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let numberOfWeeks: number = parseInt(event.target.value);
    numberOfWeeks = (numberOfWeeks >= 0) ? numberOfWeeks : 0;

    dispatchFn(lotSizeOptimizationTabActions.setTempSelectedSlicers({
      ...tempSelectedSlicers,
      number_of_weeks: numberOfWeeks
    }));
  }

  const siteFilterChangeHandler = (event: any) => {
    setTimeout(() => {
      setFilteredSiteSlicer((prev: any) => {
        const filterText: string = event.target.value;
        const filteredSiteSlicer: { name: string; value: string; }[] = filterText.length > 0 ? (
          slicers.site_codes.filter(
            (site_code: { name: string; value: string; }) => site_code.name.toLowerCase().includes(event.target.value.toLowerCase())
          )
        ) : slicers.site_codes;

        return [...filteredSiteSlicer];
      });
    }, 500);
  };

  // Function to set selected filters
  const setSelectedFilters = () => {
    dispatchFn(lotSizeOptimizationTabActions.resetTabApiDataFetched(null));
    dispatchFn(lotSizeOptimizationTabActions.setSelectedSlicers(tempSelectedSlicers));
  };

  // Event handler to reset selected filters
  const resetSelectedFilters = (event: any) => {
    const defaultLSOSlicers: LSOSelectedSlicerModel = getDefaultLSOSlicers();

    if (
      tempSelectedSlicers.site_codes.toString() !== defaultLSOSlicers.site_codes.toString() ||
      tempSelectedSlicers.group_policy_name !== defaultLSOSlicers.group_policy_name ||
      tempSelectedSlicers.part_code !== defaultLSOSlicers.part_code ||
      tempSelectedSlicers.number_of_weeks !== defaultLSOSlicers.number_of_weeks
    ) {

      dispatchFn(lotSizeOptimizationTabActions.resetSelectedSlicers(null));
      dispatchFn(lotSizeOptimizationTabActions.resetTabApiDataFetched(null));
    }
  };

  const slicerCheckboxChangeHandler = (event: any): void => {
    try {
      const { value, checked } = event.target;
      var newSelectedSlicers: LSOSelectedSlicerModel = { ...tempSelectedSlicers };

      if (value === 'All' && checked) {
        newSelectedSlicers.site_codes = ['All']
        dispatchFn(lotSizeOptimizationTabActions.setTempSelectedSlicers(newSelectedSlicers))

      }
      else if (value !== 'All' && checked) {
        newSelectedSlicers.site_codes = newSelectedSlicers.site_codes.filter((v: any) => v !== 'All');
        newSelectedSlicers.site_codes.push(value);
        dispatchFn(lotSizeOptimizationTabActions.setTempSelectedSlicers(newSelectedSlicers))

      } else if (value !== 'All' && !checked) {
        newSelectedSlicers.site_codes = newSelectedSlicers.site_codes.filter((v: any) => v !== value);

        if (newSelectedSlicers.site_codes.length === 0) {
          newSelectedSlicers.site_codes = ['All']
          dispatchFn(lotSizeOptimizationTabActions.setTempSelectedSlicers(newSelectedSlicers));
        }
        else {
          newSelectedSlicers.site_codes = newSelectedSlicers.site_codes.filter((v: any) => v !== value);
          dispatchFn(lotSizeOptimizationTabActions.setTempSelectedSlicers(newSelectedSlicers));
        }
      }

    } catch (error: any) {
      console.error(error);
    }
  };

  const dropdownItemChangeHandler = (dropdownItemValue: any) => {
    dispatchFn(lotSizeOptimizationTabActions.setTempSelectedSlicers({
      ...tempSelectedSlicers,
      group_policy_name: dropdownItemValue
    }));
  };

  useEffect(() => {
    slicers.part_code.forEach((item: {
      name: string;
      value: string;
      group_policy_name: string;
    }) => {
      if (item.group_policy_name === tempSelectedSlicers.group_policy_name) {
        setPartCode(item.value)
      }
    });
  }, [tempSelectedSlicers.group_policy_name, slicers.part_code]);

  useEffect(() => {
    setFilteredSiteSlicer([...slicers.site_codes]);
  }, [slicers.site_codes]);

  return (
    <>
      <div className="col">

        <div className="dropdown d-inline me-1">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            disabled
          >
            <span>
              <LuWarehouse /> &nbsp;Part Filter: {partCode}
            </span>
          </button>
        </div>

        <Dropdown
          id="group-policy-dropdown"
          icon={<FaLayerGroup size={15} />}
          label="Group Policy"
          defaultValue={tempSelectedSlicers.group_policy_name}
          items={slicers.group_policy_name}
          onItemChanged={(value: any) => dropdownItemChangeHandler(value)}
        />

        <div className="dropdown d-inline me-1">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <LuWarehouse /> &nbsp;Site Filter: {(
                (tempSelectedSlicers.site_codes.length > 1) ?
                  "Multiple" : tempSelectedSlicers.site_codes[0]
              )}
            </span>
          </button>

          <ul className="dropdown-menu scrollable-dropdown">
            <input
              type="text"
              id="warehouse-filter-input"
              className="form-control form-control-sm ms-2 mb-2"
              style={{ width: "90%" }}
              placeholder={"\u{1F50E} Search..."}
              onChange={siteFilterChangeHandler}
            />
            {Object.keys(slicers).length > 0 &&
              filteredSiteSlicer.map(
                (site: { name: string; value: string; }, siteIdx: any) =>
                (
                  <li key={`site_code-${siteIdx}`}>
                    <a className="dropdown-item">
                      <div className="form-check text-dark">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`site_code-${siteIdx}`}
                          onChange={slicerCheckboxChangeHandler}
                          value={site.value}
                          checked={
                            tempSelectedSlicers.site_codes.findIndex(
                              (value: string) => {
                                return value === site.value
                              }
                            ) !== -1 ? true : false
                          }
                        />
                        <label
                          htmlFor={`site_code-${siteIdx}`}
                          className="form-check-label"
                        >
                          {site.name}
                        </label>
                      </div>
                    </a>
                  </li>
                )
              )}
          </ul>
        </div>

        <input
          className="form-control form-control-sm border-1 border-dark rounded-2 segoe-font"
          type="number"
          min={0}
          max={slicers.number_of_weeks}
          step={1}
          onChange={numOfWeeksChangeHandler}
          value={tempSelectedSlicers.number_of_weeks}
          style={{
            display: "inline",
            width: "100px",
            paddingBottom: "8px",
            marginRight: "5px",
            textAlign: "center"
          }}
          placeholder="Weeks"
        />

        <div className="d-inline me-1">
          <button type="button" className="btn btn-sm text-white" style={{ backgroundColor: "#094780" }} onClick={setSelectedFilters}>
            <div className="d-flex justify-content-center align-items-center">
              <IoChevronForwardCircleOutline size={20} /> &nbsp;Apply filters
            </div>
          </button>
        </div>

        <div className="d-inline">
          <button type="button" className="btn btn-sm btn-warning" onClick={resetSelectedFilters}>
            <div className="d-flex justify-content-center align-items-center">
              <BiReset size={20} /> &nbsp;Reset filters
            </div>
          </button>
        </div>
      </div>
    </>
  );
}
export default LotSizeOptimizationSlicers