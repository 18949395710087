import { AgGridReact } from "ag-grid-react";
import { AG_GRID_MODULES, AG_GRID_OPTIONS, INVENTORY_TRACKING_ID, TOAST_CONTAINER_ID } from "../../../shared/constants";
import { ColumnsToolPanelModule, FiltersToolPanelModule, SetFilterModule } from "ag-grid-enterprise";
import { useCallback, useEffect, useRef, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { agGridNumberFormatter, getToastOptions } from "../../../shared/functions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import useFetch from "../../../hooks/useFetchMSAL";
import { controllTowerTabActions } from "../../../store/slices/control-tower-tab-slice";


const InventoryTrackingGrid = (props:{gridHeight:any}) => {
    const [{ data, loading, error }, fetchData] = useFetch([]);

    const agGridRef = useRef<AgGridReact>(null);
    const [gridRowDataCopy, setGridRowDataCopy] = useState<[]>([]);
    const [gridColDef, setGridColDef] = useState<any>([]);
    const [summaryGridRowData, setGridRowData] = useState<any>([]);
    const tabApiDataFetched: boolean = useSelector((state: RootState) => state.controllTowerTab.tabApiDataFetched)[INVENTORY_TRACKING_ID];
    const activeUpperSubTabId: string = useSelector((state: RootState) => state.controllTowerTab.activeSubTabId.upper);
    const dispatchFn = useDispatch();

    const selectedSlicers = useSelector((state: RootState) => state.controllTowerTab.selectedGridSlicers);

    const colNames = (el:string) => {
        if(el === "part_display_name")
            return "Part Name"
        else if(el === "location_display_name")
            return "Site Name"
        else if(el === "arrival_date")
            return "Arrival Date"
        else if(el === "available_inventory")
            return "Available Inventory"
        else if(el === "avg_qty")
            return "Average Quantity"
        else if(el === "weeks_of_supply")
            return "Weeks of Supply"
        else if(el === "wos_classification")
            return "WOS Classification"
        else if(el === "container_count")
            return "Container Count"
        else if(el === "inbound_qty")
            return "Inbound Quantity"
    }

    const generateColDef = (rowData:any) =>{
        const coldefs:any = [];
        Object.keys(rowData).forEach(e =>{
            let obj:any = {
                headerName: colNames(e),
                field: e,
                cellDataType: e==="arrival_date"? "date" :e==='weeks_of_supply' ||
                 e==="available_inventory" || 
                 e==="avg_qty" || 
                 e==="inbound_qty"?'number':'text',
                rowGroup: e === 'part_code'||e === 'site_code' ? true:false,
                hide: e === 'part_code'||e === 'site_code' ? true:false,
                pinned: e === 'part_code'||e === 'site_code' ? "left":"",
                cellClass: (e==="arrival_date"? "date" :e==='weeks_of_supply' ||
                    e==="available_inventory" || 
                    e==="avg_qty" || 
                    e==="inbound_qty") ? "ag-right-aligned-cell" : "ag-left-aligned-cell",
                headerClass: "ag-left-aligned-cell",
                cellRenderer: (p:any) => {
                    if(e === "weeks_of_supply" || e === "available_inventory" || e === "avg_qty" || e === "inbound_qty"){
                        return agGridNumberFormatter(p.value)
                    }
                    else{
                        return p.value
                    }
                },
                cellStyle: (p:any) => {
                    if (e === "weeks_of_supply"){
                        return (
                            {   
                                textAlign: 'center',
                                backgroundColor: p.node.data.wos_classification === "No Forecast" || p.node.data.wos_classification === "No Inventory" ? "#F78DA7": 
                                                    p.node.data.wos_classification === "<1 Weeks" ? "#c37c81" :
                                                    p.node.data.wos_classification === "1-2 Weeks" ? "#ff9a6f" :
                                                    p.node.data.wos_classification === "2-4 Weeks" ? "#78c161" :
                                                    p.node.data.wos_classification === "4-8 Weeks" ? "#7BDCB5" :
                                                    p.node.data.wos_classification === "8-12 Weeks" ? "#9d5ed5" : 
                                                    p.node.data.wos_classification === ">12 Weeks" ? "#c37c81" : 
                                                    'white', 
                                color: p.node.data.wos_classification === "<1 Weeks" || p.node.data.wos_classification === ">12 Weeks" ? "white" : 'black'
                            }
                        )
                    } else if (e === "inbound_qty" || e === "average_qty" || e === "available_inventory"){
                        return (
                            {textAlign: 'right'}
                        )
                    }
                },
                width: 180,
            };
            
            coldefs.push(obj)

        });

        return coldefs;
    }


    /**
     * Async function to fetch data from replenishment plan summary API
     */
    const fetchInventoryTracking = useCallback(async (): Promise<void> => {
        const fetchingDataToastId = toast.loading('Fetching inventory tracking grid data...', {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions('loading')
        });

        try {
            const fetchAPIResponse: AxiosResponse = await fetchData("/get-inventory-tracking-report", {
                method: "POST",
                data: {
                    site_code: selectedSlicers.location_names,
                    part_code: selectedSlicers.part_names,
                    // number_of_weeks: selectedSlicers.number_of_weeks
                }
            });

            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });

            if (fetchAPIResponse.data.data) {
                console.log(fetchAPIResponse.data.data[0])
                if(fetchAPIResponse.data.data.length>0){
                    setGridRowData(JSON.parse(JSON.stringify(fetchAPIResponse.data.data)));
                    setGridRowDataCopy(JSON.parse(JSON.stringify(fetchAPIResponse.data.data)));
                    setGridColDef((prevColDef:any) => generateColDef(JSON.parse(JSON.stringify(fetchAPIResponse.data.data[0]))));
                }else{
                    setGridRowData([]);
                setGridRowDataCopy([]);
                setGridColDef((prevColDef:any) => generateColDef(JSON.parse(JSON.stringify({
                    "part_code": "PL6-1",
                    "site_code": "EVOBSCUS01",
                    "arrival_date": 1719964800000,
                    "available_inventory": 88553,
                    "avg_qty": null,
                    "weeks_of_supply": null,
                    "wos_classification": "No Forecast",
                    "container_count": 2,
                    "inbound_qty": 177106
                }))));
                }
                dispatchFn(
                    controllTowerTabActions.setTabApiDataFetched({
                      [INVENTORY_TRACKING_ID]: true,
                    })
                  );
                
            } else {
                toast.error('Error in fetching summary data', {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions('error')
                });
            }

        } catch (error: Error | AxiosError | any) {
            console.error("Error ", error)
            console.error(`Request Error: ${error.name}`);
            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });
            toast.error((
                error?.response?.data?.error ||
                'Error in fetching data'
            ), {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions('error')
            });
        }
    }, [selectedSlicers, TOAST_CONTAINER_ID, fetchData]);

    useEffect(()=>{
        if (
            !tabApiDataFetched &&
            activeUpperSubTabId === INVENTORY_TRACKING_ID
          ) {
            fetchInventoryTracking();
          }
    },[fetchInventoryTracking,
        tabApiDataFetched,
        activeUpperSubTabId,])


    return (
        <>
            <div className="ag-theme-balham mt-2" style={{ height: props.gridHeight, maxHeight: props.gridHeight, width: '100%', overflowX: 'auto', overflowY: 'auto' }}>
                <AgGridReact
                    ref={agGridRef}
                    rowData={gridRowDataCopy}
                    columnDefs={gridColDef}
                    groupHideOpenParents={true}
                    gridOptions={{
                        ...(AG_GRID_OPTIONS as any),
                        suppressMenuHide: true,
                        suppressAggFuncInHeader: true,
                        // getRowStyle: gridRowStyleHandler
                    }}
                    defaultColDef={{
                        sortable: false
                    }}
                    modules={[
                        ...AG_GRID_MODULES,
                        ColumnsToolPanelModule,
                        FiltersToolPanelModule,
                        SetFilterModule
                    ]}
                    autoGroupColumnDef={{
                        cellRendererParams: {
                            suppressCount: true
                        }
                    }}
                    animateRows={true}
                    groupDisplayType="multipleColumns"
                    
                    // onCellValueChanged={cellValueChangeHandler}
                    // processCellForClipboard={processCellForClipboard}
                />
            </div>
        </>
    )
}
export default InventoryTrackingGrid