import { useDispatch, useSelector } from "react-redux";

import TabDetail from "../../../models/TabDetail.model";
import { MdOutlineInventory2, MdOutlineNextWeek } from "react-icons/md";
import { RootState } from "../../../store";
import ControlTowerGrid from "./ControlTowerGrid";
import WeeksOfSupplyGrid from "./WeeksOfSupplyGrid";
import { controllTowerTabActions } from "../../../store/slices/control-tower-tab-slice";
import { CUTS_OBSERVER, INVENTORY_TRACKING_ID, WEEKS_OF_SUPPLY_ID } from "../../../shared/constants";
import Tabs from "../../UI/Tabs/Tabs";
import { IoCutSharp } from "react-icons/io5";
import ControllTowerSlicers from "./ControlTowerSlicers";
import InventoryTrackingGrid from "./InventoryTrackingGrid";
import { useEffect } from "react";

const ControlTowerTab = () => {    
    // Variables
    const dispatchFn = useDispatch();
    const activeSubTabId: { 
        upper: string; 
        lower: string; 
      } = useSelector((state: RootState) => state.controllTowerTab.activeSubTabId);
    const mappings = useSelector((state:RootState)=>state.controllTowerTab.mappings);
    const slicers:any = useSelector((state:RootState)=>state.controllTowerTab.slicers);
    const lowerSectionSubTabs: TabDetail[] = [
        {
            id: WEEKS_OF_SUPPLY_ID,
            name: "WOS < 2 & WOS > 12 Observed",
            icon: <MdOutlineNextWeek />,
            content: (
                <WeeksOfSupplyGrid gridHeight="30vh" />
            )
        },
    ];

    const upperSectionSubTabs: TabDetail[] = [
        {
            id: CUTS_OBSERVER,
            name: 'Cuts Observed',
            icon: <IoCutSharp />,
            content: (<ControlTowerGrid 
                gridHeight="37vh"
            />)
        },
        {
            id: INVENTORY_TRACKING_ID,
            name: 'Inventory Tracking',
            icon: <MdOutlineInventory2 />,
            content: (<InventoryTrackingGrid 
                gridHeight="37vh"
            />)
        },

    ]

    const setActiveSubTabId = (section: "upper" | "lower", activeSubTabId: string): void => {
        dispatchFn(controllTowerTabActions.setActiveSubTabId({
          [section]: activeSubTabId
        }));
      };

    return (
        <div className="container-fluid">

            <div className="row mb-2">
                <div className="col col-8 text-start">
                    <ControllTowerSlicers partSiteSourceMapping={mappings} slicers={slicers} />
                </div>
            </div>
            
            <Tabs
                    tabs={upperSectionSubTabs}
                    activeTabIndex={upperSectionSubTabs.findIndex(tab => tab.id === activeSubTabId.upper)}
                    getActiveTabId={(tabId: string) => setActiveSubTabId("upper", tabId)} 
                />

            <div className="mt-2">
                <Tabs
                    tabs={lowerSectionSubTabs}
                    activeTabIndex={lowerSectionSubTabs.findIndex(tab => tab.id === activeSubTabId.lower)}
                    getActiveTabId={(tabId: string) => setActiveSubTabId("lower", tabId)} 
                />
            </div>
        </div>
    );
};

export default ControlTowerTab;
