import { createSlice, PayloadAction, Reducer, Slice } from "@reduxjs/toolkit";
import { PlanningParametersTabSlice, UpdateEditedStatusPayload } from "../../models/Redux/PlanningParametersTabSlice.model";
import { managePlanningParametersSlicersInStorage } from "../../shared/functions";
import { LOT_SIZE_GROUPS_TAB_ID, LOT_SIZE_OPTIMIZATION_POLICY_TAB_ID, ORDER_POLICY_TAB_ID, PART_SITE_SOURCE_TAB_ID, SAFETY_STOCK_POLICY_TAB_ID, TIME_PHASED_SAFETY_STOCK_TAB_ID } from "../../shared/constants";

const initialState: PlanningParametersTabSlice = {
    activeSubTabId: {
        upper: PART_SITE_SOURCE_TAB_ID,
        lower: SAFETY_STOCK_POLICY_TAB_ID
    },
    tempSelectedSlicers:{
        part_name: ["All"],
        source_value: ["All"],
        location_name: ["All"],
      },
    tabApiDataFetched: {
        [PART_SITE_SOURCE_TAB_ID]: false,
        [LOT_SIZE_GROUPS_TAB_ID]: false,
        [SAFETY_STOCK_POLICY_TAB_ID]: false,
        [TIME_PHASED_SAFETY_STOCK_TAB_ID]: false,
        [ORDER_POLICY_TAB_ID]: false,
        [LOT_SIZE_OPTIMIZATION_POLICY_TAB_ID]: false
    },
    selectedGridSlicers: managePlanningParametersSlicersInStorage(),
    isSafetyStockPolicyUpdated: false,
    isTimePhasedSafetyStockUpdated: false,
    isOrderPolicyUpdated: false,
    isLotSizeOptimizationPolicyUpdated: false,
    isPlanningParametersTabEdited: {
        partSiteSource: false,
        lotSizeGroups: false,
        customerWarehouseMapping: false,
        safetyStockPolicy: false,
        timePhasedSafetyStock: false,
        orderPolicy: false,
        lotSizeOptimizationPolicy: false
    }
};

const planningParametersTabSlice: Slice = createSlice({
    name: "planningParameters",
    initialState,
    reducers: {
        setTempSelectedSlicers: (state, action) => {
            state.tempSelectedSlicers = action.payload;
        },
        setSelectedGridSlicers: (state, action) => {
            state.selectedGridSlicers = action.payload;
        },
        setIsSafetyStockPolicyUpdated: (state, action) => {
            state.isSafetyStockPolicyUpdated = action.payload;
        },
        setIsTimePhasedSafetyStockUpdated: (state, action) => {
            state.isTimePhasedSafetyStockUpdated = action.payload;
        },
        setIsOrderPolicyUpdated: (state, action) => {
            state.isOrderPolicyUpdated = action.payload;
        },
        setIsLotSizeOptimizationPolicyUpdated: (state, action) => {
            state.isLotSizeOptimizationPolicyUpdated = action.payload;
        },
        setIsPlanningParametersTabEdited(
            state,
            action: PayloadAction<UpdateEditedStatusPayload>
          ) {
            let { key, value } = action.payload;
            if (state.isPlanningParametersTabEdited.hasOwnProperty(key)) {
              state.isPlanningParametersTabEdited[key] = value;
            }
        },
        setActiveSubTabId: (state, action: {
            payload: {
                upper?: string;
                lower?: string;
            }
        }) => {
            if (action.payload.upper) {
                state.activeSubTabId.upper = action.payload.upper;
            }

            if (action.payload.lower) {
                state.activeSubTabId.lower = action.payload.lower;
            }
        },
        setTabApiDataFetched: (state, action: { payload: { [key: string]: boolean; } }) => {
            state.tabApiDataFetched = {
                ...state.tabApiDataFetched,
                ...action.payload
            };
        },
        resetTabApiDataFetched: (state, action) => {
            state.tabApiDataFetched = {
                [SAFETY_STOCK_POLICY_TAB_ID]: false,
                [TIME_PHASED_SAFETY_STOCK_TAB_ID]: false,
                [ORDER_POLICY_TAB_ID]: false
            };
        }
    }
});

export const planningParametersTabActions = planningParametersTabSlice.actions;

export default planningParametersTabSlice.reducer as Reducer<PlanningParametersTabSlice>;
