import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { MdOutlineFileDownload } from "react-icons/md";

import useFetch from "../../../hooks/useFetchMSAL";
import PlannedSupplyReceipt from "../../../models/ReplenishmentPlan/PlannedSupplyReceipt.model";
import { RootState } from "../../../store";
import {
  AG_GRID_DEFAULT_COL_DEF,
  AG_GRID_MODULES,
  AG_GRID_OPTIONS,
  LSO_BUY_PLAN_TAB_ID,
  TOAST_CONTAINER_ID,
} from "../../../shared/constants";
import {
  agGridNumberFormatter,
  getToastOptions,
} from "../../../shared/functions";
import { LotSizeOptimizationTabFetchAPI, ReplenishmentPlanTabFetchAPI } from "../../../models/APIResponses.model";
import BuyPlan from "../../../models/ReplenishmentPlan/BuyPlan.model";
import { lotSizeOptimizationTabActions } from "../../../store/slices/lot-size-optimization-tab-slice";
import { LSOSelectedSlicerModel } from "../../../models/LotSizeOptimization/LSOSlicer.model";

const LSOBuyPlanGrid = (props: { gridHeight: string }) => {
  // States
  const [gridRowData, setGridRowData] = useState<PlannedSupplyReceipt[]>([]);
  const gridRef = useRef<AgGridReact>(null);

  // Variables
  const [, fetchData] = useFetch([]);
  
  const tabApiDataFetched: boolean = useSelector(
    (state: RootState) => state.lotSizeOptimizationTab.tabApiDataFetched
  )[LSO_BUY_PLAN_TAB_ID];

  const activeSubTabId: string = useSelector(
    (state: RootState) => state.lotSizeOptimizationTab.activeSubTabId
  );
  const selectedSlicers: LSOSelectedSlicerModel = useSelector(
    (state: RootState) => state.lotSizeOptimizationTab.selectedSlicers
  );
  const dispatchFn = useDispatch();
  const gridColDef: any[] = [
    {
      field: "site",
      tooltipField: "site",
      headerTooltip: "Site Name",
      cellDataType: "text",
      headerName: "Site Name",
    },
    {
      field: "sku",
      tooltipField: "sku",
      headerTooltip: "SKU Code",
      cellDataType: "text",
      headerName: "SKU Code",
    },
    {
      field: "supply_type",
      tooltipField: "supply_type",
      headerTooltip: "Supply Type",
      cellDataType: "text",
      headerName: "Supply Type",
      width: 150,
    },
    {
      field: "po_number",
      tooltipField: "po_number",
      headerTooltip: "PO Number",
      cellDataType: "text",
      headerName: "PO Number",
    },
    {
      field: "recommended_release_date",
      tooltipField: "recommended_release_date",
      headerTooltip: "Projected Release Date",
      cellDataType: "text",
      headerName: "Projected Release Date",
    },
    {
      field: "expected_receipt_date",
      tooltipField: "expected_receipt_date",
      headerTooltip: "Projected Receipt Date",
      cellDataType: "text",
      headerName: "Projected Receipt Date",
    },
    {
      field: "payable_date",
      tooltipField: "payable_date",
      headerTooltip: "Payable Date",
      cellDataType: "text",
      headerName: "Payable Date",
    },
    {
      field: "quantity",
      headerTooltip: "Quantity",
      cellDataType: "number",
      cellClass: "ag-right-aligned-cell",
      headerClass: "ag-left-aligned-cell",
      headerName: "Quantity",
      tooltipValueGetter: (params: any) =>
        agGridNumberFormatter(params.data.quantity, 2),
      valueFormatter: (params: any) =>
        agGridNumberFormatter(params.data.quantity, 2),
    },
    {
      field: "total_cost",
      headerTooltip: "Total Cost",
      cellDataType: "number",
      cellClass: "ag-right-aligned-cell",
      headerClass: "ag-left-aligned-cell",
      headerName: "Total Cost ($)",
      tooltipValueGetter: (params: any) =>
        agGridNumberFormatter(Math.round(params.data.total_cost), 2),
      valueFormatter: (params: any) =>
        agGridNumberFormatter(Math.round(params.data.total_cost), 2),
    },
  ];

  const fetchBuyPlan = useCallback(async () => {
    const fetchingDataToastId = toast.loading("Fetching buy plan data...", {
      containerId: TOAST_CONTAINER_ID,
      ...getToastOptions("loading"),
    });
    try {
      const fetchAPIResponse: AxiosResponse<LotSizeOptimizationTabFetchAPI<BuyPlan[]>> = await fetchData(`/lsop-buy-plan`, {
        method: "POST",
        data: { 
          site_codes: selectedSlicers.site_codes,
          group_policy_name: selectedSlicers.group_policy_name,
          number_of_weeks: selectedSlicers.number_of_weeks
        },
      });

      toast.dismiss({
        id: fetchingDataToastId,
        containerId: TOAST_CONTAINER_ID,
      });

      if (fetchAPIResponse.data.data) {
        setGridRowData(fetchAPIResponse.data.data.result);
        dispatchFn(
          lotSizeOptimizationTabActions.setSlicers(fetchAPIResponse.data.data.slicers)
        );
        dispatchFn(
          lotSizeOptimizationTabActions.setTabApiDataFetched({
            [LSO_BUY_PLAN_TAB_ID]: true,
          })
        );

      } else {
        toast.error("Error in fetching buy plan data", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    } catch (error: Error | any) {
      console.error(`Request Error: ${error}`);
      toast.dismiss({
        id: fetchingDataToastId,
        containerId: TOAST_CONTAINER_ID,
      });
      toast.error(error?.response?.data?.error || "Error in fetching data", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  }, [dispatchFn, fetchData, selectedSlicers]);

  const downloadReports = useCallback(() => {
    const gridApi = gridRef.current?.api;
    if (!gridApi) return;

    const originalColumnDefs = gridApi.getColumnDefs();

    // Update column definitions to hide 'Actions' column
    const updatedColumnDefs = originalColumnDefs?.map((colDef: any) => {
      if (colDef.headerName === "Actions") {
        return { ...colDef, hide: true };
      }
      return colDef;
    });

    // Update grid options to apply changes
    gridApi.updateGridOptions({
      columnDefs: updatedColumnDefs,
    });

    // Retrieve all rows
    const allRows: any = [];
    gridApi.forEachNode((node: any) => allRows.push(node.data));

    // Temporarily set the grid data to filteredRows for export
    gridApi.updateGridOptions({
      rowData: allRows,
    });

    // Export data as Excel
    gridApi.exportDataAsExcel({
      sheetName: "Filtered Data",
    });

    // Restore original data after export
    gridApi.updateGridOptions({
      rowData: gridRowData,
    });
  }, [gridRowData]);

  const downloadGlobalReport = () => {
    window.open(process.env.REACT_APP_API_BASE_PATH+'/download-global-buy-plan')
  }

  useEffect(() => {
    if (!tabApiDataFetched && activeSubTabId === LSO_BUY_PLAN_TAB_ID) {
      fetchBuyPlan();
    }
  }, [activeSubTabId, selectedSlicers, tabApiDataFetched, fetchBuyPlan]);

  return (
    <>
      <div className="d-flex justify-content-end">
        <button
          title="Download Report"
          className="btn btn-sm btn-custom-primary mx-2"
          disabled={gridRowData && !gridRowData.length}
          onClick={downloadReports}
        >
          <MdOutlineFileDownload /> &nbsp;Download Report
        </button>

        <button
          title="Download Global Report"
          className="btn btn-sm btn-custom-primary mx-2"
          disabled={gridRowData && !gridRowData.length}
          onClick={downloadGlobalReport}
        >
          <MdOutlineFileDownload /> Download Global Report
        </button>
      </div>

      <div
        className="ag-theme-balham mt-2"
        style={{
          height: props.gridHeight,
          maxHeight: props.gridHeight,
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={gridRowData}
          columnDefs={gridColDef}
          gridOptions={{
            ...(AG_GRID_OPTIONS as any),
          }}
          defaultColDef={{
            width: 100,
            ...(AG_GRID_DEFAULT_COL_DEF as any),
          }}
          modules={AG_GRID_MODULES}
        />
      </div>
    </>
  );
};
export default LSOBuyPlanGrid;
